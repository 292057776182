import { FooterBottomType } from '@/typedir/types'

export const footerBottomList: FooterBottomType[] = [
  {
    url: '/',
    label: '株式会社Relicホールディングストップ'
  },
  {
    url: '/group/campfire-enjine/policy',
    label: 'プライバシーポリシー（株式会社CAMPFIRE ENjiNE）'
  }
]
