import { HeaderNavListType } from '@/typedir/types'

export const headerNavList: HeaderNavListType[] = [
  { url: '/group/campfire-enjine', label: 'トップ', labelEnglish: 'TOP' },
  {
    url: '/group/campfire-enjine/news',
    label: 'お知らせ',
    labelEnglish: 'NEWS'
  },
  {
    url: '/group/campfire-enjine#company',
    label: '会社情報',
    labelEnglish: 'COMPANY'
  },
  {
    url: 'https://relic.co.jp/recruit/',
    label: '採用情報',
    labelEnglish: 'RECRUIT'
  },
  {
    url: 'https://relic.co.jp/services/enjine/contact/',
    label: 'お問い合わせ',
    isContact: true
  }
]
