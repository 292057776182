import React from 'react'
import { Helmet } from 'react-helmet'

import '@/styles/style.scss'
import favicon from '@/images/favicon2.ico'
// import appleIcon from '@/images/favicon_180.png'

export const CampfireEnjineHead = (props: {
  title: string
  url: string
}): JSX.Element => {
  const { title, url } = props

  return (
    <Helmet>
      <html lang="ja" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{title}</title>
      <meta
        name="description"
        content="企業のクラウドファンディング事業を支援し、新たな挑戦が生まれる仕組みを実装する"
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://relic-holdings.co.jp/${url}`} />
      <meta
        property="og:image"
        content="https://relic-holdings.co.jp/OGP_1200*630.png"
      />
      <meta
        property="og:description"
        content="企業のクラウドファンディング事業を支援し、新たな挑戦が生まれる仕組みを実装する"
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content="株式会社CAMPFIRE ENjiNE" />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href={favicon} />
      <link rel="apple-touch-icon-precomposed" href={favicon} sizes="180x180" />
    </Helmet>
  )
}
